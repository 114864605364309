import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clanResponseAdapter } from 'api/helpers/clanAdapter';
import { errorMessages } from 'constants/messages';
import { reducersNames } from 'constants/reducers';
import { IClan } from 'interfaces/clan';
import { IPlayer } from 'interfaces/player';
import { TClanResponse } from 'types/clan';

import { filteredDataBySearchValue } from 'utils';

import {
  deleteClanMemberThunk,
  deleteClanThunk,
  getClanMembersThunk,
  getClansThunk,
  getClanThunk,
  updateClanMemberThunk,
  updateClanThunk,
} from './actions';

interface IClanState {
  clans: IClan[];
  filteredClans: IClan[];
  convertedClansData: Pick<IClan, 'name' | 'work_area'>[];
  pending: boolean;
  error: string | null;
  selectedClan: IClan | null;
  initialSelectedClan: IClan | null;
}

const initialState: IClanState = {
  clans: [],
  filteredClans: [],
  convertedClansData: [],
  error: null,
  pending: false,
  selectedClan: null,
  initialSelectedClan: null,
};

const clanSlice = createSlice({
  name: reducersNames.CLAN,
  initialState,
  reducers: {
    setNewClan(state, { payload }: PayloadAction<IClan>) {
      state.selectedClan = { ...payload };
    },
    modifySelected(state, { payload }: PayloadAction<Partial<IClan>>) {
      if (state.selectedClan) {
        state.selectedClan = { ...state.selectedClan, ...payload };
      }
    },
    setQuerySearch(state, { payload }) {
      const filteredConvertedClanData = filteredDataBySearchValue(
        state.convertedClansData,
        payload
      );

      const filteredClans = state.clans?.filter((clan) =>
        filteredConvertedClanData?.some((data) => clan.name === data.name)
      );

      if (filteredClans?.length) {
        state.filteredClans = filteredClans;
      } else {
        state.filteredClans = state.clans;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getClansThunk.fulfilled,
        (state, action: PayloadAction<TClanResponse[]>) => {
          const clans = action.payload.map(clanResponseAdapter);

          const convertedClansData = clans.map(({ name, work_area }) => ({
            name,
            work_area,
          }));

          state.convertedClansData = convertedClansData;
          state.clans = clans;
          state.filteredClans = clans;

          state.selectedClan = null;
          state.initialSelectedClan = null;
          state.error = null;
          state.pending = false;
        }
      )
      .addCase(getClansThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getClansThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(getClanThunk.fulfilled, (state, { payload: loadedClan }) => {
        const clan = clanResponseAdapter(loadedClan);

        state.selectedClan = clan;
        state.initialSelectedClan = clan;
        state.pending = false;
        state.error = null;
      })
      .addCase(getClanThunk.pending, (state) => {
        state.error = null;
        state.pending = true;
      })
      .addCase(getClanThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(getClanMembersThunk.fulfilled, (state, action) => {
        if (state.selectedClan && state.initialSelectedClan) {
          state.selectedClan.players = action.payload;
          state.initialSelectedClan.players = action.payload;
        }
      })
      .addCase(updateClanThunk.fulfilled, (state, { meta: { arg } }) => {
        const index = state.clans.findIndex((clan) => clan.id === arg.id);

        state.clans[index] = arg;
        state.selectedClan = arg;
        state.initialSelectedClan = arg;
        state.error = null;
        state.pending = false;
      })
      .addCase(updateClanThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateClanThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(deleteClanThunk.fulfilled, (state, { meta: { arg } }) => {
        state.clans = state.clans.filter((clan) => clan.id !== arg);
        state.error = null;
        state.pending = false;
      })
      .addCase(deleteClanThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(deleteClanThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(deleteClanMemberThunk.fulfilled, (state) => {
        state.error = null;
        state.pending = false;
      })
      .addCase(deleteClanMemberThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(deleteClanMemberThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(updateClanMemberThunk.fulfilled, (state, { payload }) => {
        if (state.selectedClan && payload.id) {
          const updatedPlayerData = state.selectedClan.players.reduce(
            (players: IPlayer[], player) => {
              players.push(
                player.id === payload.id ? { ...player, ...payload } : player
              );

              return players;
            },
            []
          );

          state.selectedClan = {
            ...state.selectedClan,
            players: updatedPlayerData,
          };

          state.initialSelectedClan = {
            ...state.selectedClan,
            players: updatedPlayerData,
          };
        }
      });
  },
});

export const { actions: clanActions, reducer: clanReducer } = clanSlice;

export * from './selectors';
