import { FC } from 'react';
import { IOption } from 'interfaces';
import { gameTypeOptions, tagOptions } from 'mock';

import { Button, MultiSelect } from 'components/ui';
import TextArea from 'components/ui/TextArea';

interface RecordModalProps {
  type: 'edit' | 'create';
  recordDescription: string;
  selectedGameTypes: IOption[];
  selectedTags: IOption[];
  selectedComputer: IOption[];
  onSelectGameTypes: (value: IOption[]) => void;
  onSelectTags: (value: IOption[]) => void;
  onSelectComputer: (value: IOption[]) => void;
  onChangeDescription: (value: string) => void;
  onSave: () => void;
  onResume: () => void;
  onRemove: () => void;
}

export const RecordModal: FC<RecordModalProps> = ({
  type,
  recordDescription,
  selectedGameTypes,
  selectedComputer,
  selectedTags,
  onSelectGameTypes,
  onSelectTags,
  onChangeDescription,
  onSelectComputer,
  onSave,
  onResume,
  onRemove,
}) => {
  // const [computerOptions, setComputerOptions] = useState<IOption[]>([]);

  // ToDo: temporarily hidden
  // useEffect(() => {
  //   const getComputersData = async () => {
  //     try {
  //       const { data } = await getComputers();

  //       const options = data.map(({ id, name }) => ({
  //         value: String(id),
  //         label: name,
  //       }));

  //       setComputerOptions(options);
  //     } catch (error) {
  //       notify.warning(errorMessages.GET_COMPUTERS);
  //     }
  //   };

  //   getComputersData();
  // }, []);

  const isEdit = type === 'edit';

  return (
    <>
      <span className="tpg-h4 text-center">
        {isEdit ? 'Редактирование' : 'Сохранить эфир'}
      </span>
      <div className="flex flex-col gap-[12px]">
        <MultiSelect
          placeholder="Характер задачи"
          value={selectedGameTypes}
          options={gameTypeOptions}
          onSelect={onSelectGameTypes}
        />
        <MultiSelect
          placeholder="Цели"
          value={selectedTags}
          options={tagOptions}
          onSelect={onSelectTags}
        />
        {/* ToDo: temporarily hidden */}
        {/* <MultiSelect
          placeholder="Техника"
          value={selectedComputer}
          options={computerOptions}
          onSelect={onSelectComputer}
        /> */}
        <TextArea
          placeholder="Описание"
          onChange={onChangeDescription}
          value={recordDescription}
          rows={2}
          textAreaClassName="!resize-none"
        />
        <Button
          onClick={onSave}
          title={isEdit ? 'Сохранить изменения' : 'Сохранить'}
          className="w-full"
        />
        {isEdit ? (
          <div className="flex flex-row gap-[12px]">
            <Button
              onClick={onResume}
              title="Отмена"
              className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
            />
            <Button
              onClick={onRemove}
              title="Удалить"
              className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
            />
          </div>
        ) : (
          <div className="flex flex-row gap-[12px]">
            <Button
              onClick={onRemove}
              title="Не сохранять"
              className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
            />
            <Button
              onClick={onResume}
              title="Возобновить"
              className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
            />
          </div>
        )}
      </div>
    </>
  );
};
