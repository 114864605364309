import { FC, useEffect, useMemo, useState } from 'react';
import { getClans } from 'api/clans';
import cn from 'classnames';
import { errorMessages } from 'constants/messages';
import { EUserRole } from 'constants/profile';
import { useAppSelector, useBreakPoint } from 'hooks';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';
import { IAccountRole, IOption } from 'interfaces';
import { IClanPlayer } from 'interfaces/player';
import { authSelector } from 'store/slices/auth/selectors';
import { ID } from 'types/common';
import { rolesValuesMap } from 'types/player';

import { filterOptionsByUniqueClan, getWordDayForm, notify } from 'utils';

import { AccessTable } from './components/AccessTable';
import { AddAccessCard } from './components/AddAccessCard';

interface AccessListProps {
  clans: IClanPlayer[];
  accountId: string;
  highPriorityRoles: IAccountRole[];
  onAddAccess: (value: IClanPlayer) => void;
  onRemoveAccess: (id: string) => void;
  onUpdateAccess: (value: IClanPlayer) => void;
  isAccessEditing: boolean;
  isBanned: boolean;
  roles: IAccountRole[];
  isNewPlayer?: boolean;
}

const isAdminForPlayer = (
  roles: IAccountRole[],
  clanId: ID,
  accountId: ID | null
) => {
  const clan = roles.find((role) => role.clan_id === clanId);

  return (
    clan?.role_name === EUserRole.ADMIN || accountId === rolesValuesMap.root
  );
};

export const AccessList: FC<AccessListProps> = ({
  clans,
  accountId,
  highPriorityRoles,
  onAddAccess,
  onRemoveAccess,
  onUpdateAccess,
  isAccessEditing,
  isBanned,
  roles,
  isNewPlayer,
}) => {
  const [isNewAccess, setNewAccess] = useState(false);
  const [clanOptions, setClanOptions] = useState<IOption[]>([]);

  const { id, role: accountRole } = useAppSelector(authSelector);

  const firteredClans = useMemo(
    () =>
      clans.filter(
        (clan) =>
          (isAdminForPlayer(highPriorityRoles, clan.clanId, id) &&
            clan.roleId !== rolesValuesMap.root) ||
          isNewPlayer
      ),
    [clans]
  );

  const availableСlans = useMemo(
    () =>
      roles.flatMap((role) =>
        (role.role_name === EUserRole.ADMIN ||
          role.role_name === EUserRole.ROOT) &&
        role.clan_id
          ? {
              value: role.clan_id,
              label: role.clan_name,
            }
          : []
      ),
    [roles]
  );

  useEffect(() => {
    if (accountRole !== EUserRole.ROOT) {
      const clanOptions = filterOptionsByUniqueClan(availableСlans, clans);

      setClanOptions(clanOptions);

      return;
    }

    const getClansData = async () => {
      try {
        const data = await getClans();

        const options = data.map(({ id, name }) => ({
          value: String(id),
          label: name,
        }));

        const clanOptions = filterOptionsByUniqueClan(options, clans);
        setClanOptions(clanOptions);
      } catch (error) {
        notify.error(errorMessages.GET_CLANS);
      }
    };

    getClansData();
  }, [clans, isNewPlayer]);

  const wordForm = getWordDayForm(firteredClans?.length, [
    'доступ',
    'доступа',
    'доступов',
  ]);

  const handleOpenCardAccess = () => {
    setNewAccess(true);
  };

  const handleClose = () => {
    setNewAccess(false);
  };

  const breakPoint = useBreakPoint();

  return (
    <div
      className={cn('w-6/12 min-h-full overflow-auto px-[16px]', {
        'w-full': breakPoint === 'mobile' || breakPoint === 'tablet',
      })}
    >
      <div className="flex flex-row justify-between bg-ultrablack py-[16px] sticky top-0 z-[2]">
        <span>{`${firteredClans?.length} ${wordForm}`}</span>
        <div
          className="group cursor-pointer flex items-center justify-center transition"
          onClick={handleOpenCardAccess}
        >
          {!!clanOptions.length &&
            (isAccessEditing || isNewPlayer) &&
            !isBanned && (
              <PlusIcon className="scale-125 group-hover:[&>g>path]:fill-bright_product [&>g>path]:transition" />
            )}
        </div>
      </div>
      <div className="flex flex-col gap-[16px] pb-[16px]">
        {isNewAccess && (
          <AddAccessCard
            accountId={accountId}
            clanOptions={clanOptions}
            onAddAccess={onAddAccess}
            onClose={handleClose}
            isNewPlayer={isNewPlayer}
          />
        )}
        {firteredClans?.map((clan) => (
          <AccessTable
            key={clan.clanId}
            accountId={accountId}
            onRemoveAccess={onRemoveAccess}
            onUpdateAccess={onUpdateAccess}
            isNewPlayer={isNewPlayer}
            isAccessEditing={isAccessEditing}
            {...clan}
          />
        ))}
      </div>
    </div>
  );
};
