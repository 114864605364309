import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createRoomChain,
  createRoomIngress,
  deleteRoom,
  getAllLiveKitTokens,
  getAllRooms,
  getClanRooms,
  getLiveKitToken,
  getRecordingSignal,
} from 'api/room';
import {
  IClanRoomsAdapted,
  ICreateRoomChainResponse,
  IDeleteRoomInput,
  IGetAllLiveKitTokens,
  IGetLiveKitTokenInput,
  ILiveKitTokenResponse,
  ITokenResponse,
  TCreateRoomInput,
} from 'api/types/room';
import { errorMessages } from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { ID } from 'types';

import { createThunk, notify } from 'utils';

export const getClanRoomsThunk = createThunk<IClanRoomsAdapted, ID>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_CLANS_ROOMS}`,
  getClanRooms
);

export const getAllRoomsThunk = createThunk<IClanRoomsAdapted[], void>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_ALL_ROOMS}`,
  getAllRooms
);

export const getLiveKitTokenThunk = createThunk<
  ITokenResponse,
  IGetLiveKitTokenInput
>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_STREAM_TOKEN}`,
  getLiveKitToken
);

export const getAllLiveKitTokensThunk = createThunk<
  { items: ILiveKitTokenResponse[] },
  IGetAllLiveKitTokens[]
>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_ALL_STREAM_TOKENS}`,
  getAllLiveKitTokens
);

export const createRoomIngressThunk = createAsyncThunk<any, TCreateRoomInput>(
  `${reducersNames.ROOM}/${asyncActionsNames.CREATE_ROOM_INGRESS}`,
  async (request, thunkAPI) => {
    const { clanId, roomName } = request;

    try {
      const data = thunkAPI
        .dispatch(
          createRoomThunk({
            roomName,
            clanId,
          })
        )
        .then(async ({ payload }) => {
          if (payload && typeof payload === 'object' && 'token' in payload) {
            try {
              const dataIngress = await createRoomIngress({ clanId, roomName });

              return dataIngress;
            } catch (error) {
              thunkAPI.dispatch(
                deleteRoomThunk({
                  clanId,
                  roomName,
                  params: { save_recording: false },
                })
              );

              return thunkAPI.rejectWithValue({
                error,
                message: errorMessages.START_RECORDING,
              });
            }
          }

          thunkAPI.dispatch(
            deleteRoomThunk({
              clanId,
              roomName,
              params: { save_recording: false },
            })
          );
        })
        .catch(async (error) =>
          thunkAPI.rejectWithValue({
            error,
            message: errorMessages.CREATE_ROOM,
          })
        );

      return data;
    } catch (error) {
      notify.error(errorMessages.CREATE_ROOM);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createRoomThunk = createThunk<
  ICreateRoomChainResponse,
  TCreateRoomInput
>(`${reducersNames.ROOM}/${asyncActionsNames.CREATE_ROOM}`, createRoomChain, {
  errorMessage: errorMessages.CREATE_ROOM,
});

export const getRecordingSignalThunk = createThunk(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_RECORDING_SIGNAL}`,
  getRecordingSignal
);

export const deleteRoomThunk = createThunk<void, IDeleteRoomInput>(
  `${reducersNames.ROOM}/${asyncActionsNames.DELETE_ROOM}`,
  deleteRoom,
  { errorMessage: errorMessages.DELETE_ROOM, callback: (_, input) => input }
);

export const deleteRoomIngressThunk = createThunk<
  void,
  Omit<IDeleteRoomInput, 'params'>
>(
  `${reducersNames.ROOM}/${asyncActionsNames.DELETE_ROOM_INGRESS}`,
  deleteRoom,
  { errorMessage: errorMessages.DELETE_ROOM }
);
