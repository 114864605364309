import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import {
  useAppDispatch,
  useAppSelector,
  useClickOutside,
  useRoles,
} from 'hooks';
import { authSelector } from 'store/slices/auth';
import { createRoomIngressThunk } from 'store/slices/room/actions';

import { AddButton } from 'components/AddButton';

interface StartStreamButtonProps {
  onOpenModal: () => void;
  className?: string;
  classNameButton?: string;
  classNameContainer?: string;
  isShowIcon?: boolean;
}

export const StartStreamButton: FC<StartStreamButtonProps> = ({
  onOpenModal,
  className,
  classNameButton,
  classNameContainer,
  isShowIcon,
}) => {
  const [isOpen, setOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () => setOpen(false));

  const navigate = useNavigate();

  const { roles, username } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  const handleOpenButton = () => {
    setOpen(!isOpen);
  };

  const getClanHost = useRoles([EUserRole.ADMIN, EUserRole.USER], roles);

  const handleStartBrowserStream = () => {
    const clanId = getClanHost();

    navigate('/stream-launch', {
      state: clanId,
    });

    setOpen(false);
  };

  const handleStartAppStream = () => {
    const clanId = getClanHost();

    if (clanId) {
      dispatch(
        createRoomIngressThunk({
          roomName: `${username}_stream`,
          clanId,
        })
      );
    }

    setOpen(false);

    onOpenModal();
  };

  return (
    <div className={cn('relative', className)} ref={containerRef}>
      <AddButton
        label="Запустить эфир"
        onClick={handleOpenButton}
        isActive={isOpen}
        className={classNameButton}
        isShowIcon={isShowIcon}
      />
      {isOpen && (
        <div
          className={cn(
            'absolute top-[30px] right-0 flex flex-col rounded-[10px] bg-ultrablack border border-solid border-tpg_light',
            classNameContainer
          )}
        >
          <span
            className="cursor-pointer tpg-c2 pt-[3px] px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
            onClick={handleStartBrowserStream}
          >
            Из браузера
          </span>
          <span
            className="cursor-pointer tpg-c2 pb-[3px] px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
            onClick={handleStartAppStream}
          >
            Из приложения
          </span>
        </div>
      )}
    </div>
  );
};
