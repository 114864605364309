import { FC } from 'react';
import cn from 'classnames';
import { useBreakPoint, useWindowSize } from 'hooks';

interface EmtyScreenProps {
  text: string;
  isOpenFullScreen?: boolean;
  className?: string;
}

export const EmtyScreen: FC<EmtyScreenProps> = ({
  text,
  isOpenFullScreen,
  className,
}) => {
  const { height } = useWindowSize();

  const breakPoint = useBreakPoint();

  const heightScreen =
    ((breakPoint === 'mobile' || breakPoint === 'tablet') &&
      !isOpenFullScreen &&
      `${height - 105}px`) ||
    (!(breakPoint === 'mobile' || breakPoint === 'tablet') &&
      !isOpenFullScreen &&
      `${height - 180}px`) ||
    `${height - 20}px`;

  return (
    <div
      className={cn(
        'flex justify-center items-center w-full bg-light rounded-[10px]',
        className
      )}
      style={{ height: heightScreen }}
    >
      <span className="tpg-h4 text-tpg_light">{text}</span>
    </div>
  );
};
