import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IClanRoomsAdapted } from 'api/types/room';
import { LP_INTERVAL_MS } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IRoomParams } from 'interfaces';
import { authSelector } from 'store/slices/auth';
import { clansSelector } from 'store/slices/clan';
import { roomActions, roomSelector } from 'store/slices/room';
import {
  getAllLiveKitTokensThunk,
  getAllRoomsThunk,
  getClanRoomsThunk,
} from 'store/slices/room/actions';
import { rolesValuesMap } from 'types/player';
import { useDebouncedCallback } from 'use-debounce';

import { Loader } from 'components/ui/Loader';
import {
  convertRoomNameToParams,
  setHiddenRoomsInSearchParams,
  updateHiddenRoomsInSearchParams,
} from 'utils';

import { Panel } from './Panel';

interface AllClansStreamsPanelProps {
  isOpenFullScreen: boolean;
  onBack: () => void;
}

export const AllClansStreamsPanel: FC<AllClansStreamsPanelProps> = ({
  isOpenFullScreen,
  onBack,
}) => {
  const [openedFullscreenClanRoom, setOpenedFullscreenClanRoom] = useState<{
    livekitName: string;
    clanId: string;
  }>();

  const { treeRooms, rooms: roomsData } = useAppSelector(roomSelector);

  const { clans } = useAppSelector(clansSelector);

  const { id: accountId, roles } = useAppSelector(authSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const showClansParams = searchParams.get('showClans');

  useEffect(() => {
    if (openedFullscreenClanRoom?.clanId) {
      const getRooms = () => {
        if (clans) {
          dispatch(getClanRoomsThunk(openedFullscreenClanRoom.clanId));
        }
      };

      getRooms();

      const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

      return () => {
        clearInterval(roomsInterval);
      };
    } else {
      const getRooms = () => {
        if (clans) {
          dispatch(getAllRoomsThunk()).then((action) => {
            if (action.meta.requestStatus === 'fulfilled') {
              const payload = action.payload as IClanRoomsAdapted[];

              const activeClans = payload.filter(({ rooms }) =>
                rooms.some((room) => room.isActive)
              );

              const newParams = updateHiddenRoomsInSearchParams(
                activeClans,
                searchParams
              );

              if (newParams) {
                setSearchParams(newParams);
              }
            }
          });
        }
      };

      getRooms();

      const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

      return () => {
        clearInterval(roomsInterval);
      };
    }
  }, [clans, openedFullscreenClanRoom, searchParams]);

  useEffect(() => {
    dispatch(roomActions.setTreeRooms({ clans, searchParams }));
  }, [roomsData, clans]);

  useEffect(() => {
    if (!searchParams.size) {
      const params = setHiddenRoomsInSearchParams(searchParams, treeRooms);
      setSearchParams(params);
    }
  }, [treeRooms]);

  const rooms = useMemo(
    () =>
      treeRooms.reduce((acc: IRoomParams[], data) => {
        if (data.rooms) {
          const convertedRooms = data.rooms.map((room) => ({
            ...room,
            clanName: data.clanName,
            clanId: data.clanId,
          }));

          acc = [...acc, ...convertedRooms];
        }

        return acc;
      }, []),
    [treeRooms]
  );

  const filterdRooms = useMemo(
    () =>
      rooms.filter(
        (room) =>
          room.accountId === accountId ||
          roles.some(
            (role) =>
              role.clan_id === room.clanId &&
              role.role_id !== rolesValuesMap.user
          )
      ),
    [rooms]
  );

  const setTokenForNewRooms = useDebouncedCallback(
    (filterdRooms: IRoomParams[], openedFullscreenClanRoom: boolean) => {
      const newRooms = filterdRooms?.filter((newRoom) => !newRoom.token);

      if (newRooms.length && !openedFullscreenClanRoom) {
        const params = newRooms.map((room) => {
          const { clan_id, room_name } = convertRoomNameToParams(
            room.livekitName
          );

          return {
            clan_id,
            room_name,
          };
        });

        if (params.length) {
          dispatch(getAllLiveKitTokensThunk(params));
        }
      }
    },
    100
  );

  useEffect(() => {
    setTokenForNewRooms(filterdRooms, !!openedFullscreenClanRoom);
  }, [filterdRooms, openedFullscreenClanRoom]);

  const roomList = useMemo(
    () =>
      openedFullscreenClanRoom?.livekitName
        ? filterdRooms.filter(
            (room) => room.livekitName === openedFullscreenClanRoom?.livekitName
          )
        : filterdRooms,
    [filterdRooms, openedFullscreenClanRoom]
  );

  useEffect(() => {
    if (!roomList.length && openedFullscreenClanRoom) {
      setOpenedFullscreenClanRoom(undefined);

      return;
    }
  }, [roomList, openedFullscreenClanRoom]);

  const handleFullscreenChange = (
    isFullOpen: boolean,
    livekitName: string,
    clanId?: string
  ) => {
    if (isFullOpen) {
      if (clanId) {
        setOpenedFullscreenClanRoom({ livekitName, clanId });
      }
    } else {
      setOpenedFullscreenClanRoom(undefined);
    }
  };

  console.log(
    'Active rooms :>>',
    filterdRooms.map(({ livekitName }) => livekitName)
  );

  const convertedTreeRooms = treeRooms.flatMap(
    ({ clanId, rooms, isHidden }) => {
      const hiddenRooms = rooms?.filter((room) => room.isHidden);

      if (!hiddenRooms.length) return { clanId };

      if (isHidden && hiddenRooms.length !== rooms.length) {
        return {
          clanId,
          hiddenRooms: hiddenRooms.map(({ livekitName }) => livekitName),
        };
      }

      return [];
    }
  );

  if (
    showClansParams &&
    !(showClansParams && JSON.stringify(convertedTreeRooms) === showClansParams)
  ) {
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <Panel
      isOpenFullScreen={isOpenFullScreen}
      onBack={onBack}
      rooms={roomList}
      onFullscreenChange={handleFullscreenChange}
    />
  );
};
