import { FC, ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { navs } from 'constants/navs';
import { EUserRole } from 'constants/profile';
import { authRoutes, navigation } from 'constants/routes';
import { useAppDispatch, useAppSelector, useBreakPoint, useRoles } from 'hooks';
import { accountSelector, authActions, authSelector } from 'store';
import { TNavItem } from 'types';

import { ControlPanelMenu } from 'components/ControlPanelMenu';
import { CopyModal } from 'components/CopyModal';
import { MobileNavigation } from 'components/MobileNavigation';
import { ConfirmModal } from 'components/ui/Modal/ConfirmModal';

import HeaderLink from './HeaderLink';
import { StartStreamButton } from './StartStreamButton';

import './style.scss';

interface IHeaderProps {
  isPageControlPanel?: boolean;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  rightButton?: ReactNode;
  isStartStream?: boolean;
  resetOptions?: () => void;
}

export const Header: FC<IHeaderProps> = ({
  isPageControlPanel = true,
  leftContent,
  centralContent,
  rightButton,
  isStartStream = false,
  resetOptions,
}) => {
  const [isOpenModalLogout, setOpenModalLogout] = useState(false);

  const [isOpenModalAppStream, setOpenModalAppStream] = useState(false);

  const { role, roles } = useAppSelector(authSelector);
  const { name } = useAppSelector(accountSelector);

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const getClanHost = useRoles([EUserRole.ADMIN, EUserRole.USER], roles);

  const breakPoint = useBreakPoint();

  const handleLogout = () => {
    dispatch(authActions.logout());
    setTimeout(navigate, 100, authRoutes.SIGN_IN);
  };

  const handleOpenModalAppStream = () => {
    setOpenModalAppStream(true);
  };

  const renderNavItem = (
    { link, title, icon, onClick }: TNavItem,
    idx: number
  ) => (
    <HeaderLink
      isActive={!!link && pathname.includes(link)}
      link={link}
      title={title}
      passiveIcon={icon || undefined}
      key={`link-${idx}`}
      onClick={onClick}
      containerClassName={breakPoint === 'mobile' ? 'px-[16px]' : ''}
    />
  );

  const handleClickLogo = () => {
    resetOptions?.();

    navigate(role ? navigation[role] : '/');
  };

  const handleStartStream = () => {
    const clanId = getClanHost();

    navigate('/stream-launch-app', {
      state: clanId,
    });

    setOpenModalAppStream(false);
  };

  const isShowMobileNavigation =
    breakPoint === 'mobile' ||
    breakPoint === 'tablet' ||
    breakPoint === 'tablet-landscape';

  return (
    <>
      <div className="flex flex-col">
        <nav className="header flex justify-center">
          <div
            className="tpg-h3 cursor-pointer flex flex-row content-center"
            onClick={handleClickLogo}
          >
            <span>ASTRA</span>
            <span className="text-bright_product">S</span>
            {breakPoint !== 'mobile' && (
              <span className="header__username text-tpg_title">
                &nbsp; {name}
              </span>
            )}
          </div>
          {!isShowMobileNavigation && (
            <div className="header__links z-[11]">
              <div className="absolute left-1/2 -translate-x-1/2 flex flex-row">
                {role && navs[role].map((nav, ind) => renderNavItem(nav, ind))}
              </div>
            </div>
          )}
          {isShowMobileNavigation && (
            <MobileNavigation list={role && navs[role]} />
          )}
          {breakPoint !== 'mobile' && (
            <span
              className="cursor-pointer tpg-b1 text-tpg_base hover:text-bright_product transition"
              onClick={() => setOpenModalLogout(true)}
            >
              Выйти
            </span>
          )}
        </nav>
        {isPageControlPanel && (
          <div className="w-full h-[48px] flex flex-row items-center justify-between bg-dark sticky top-0 z-10 px-[24px]">
            {leftContent ? (
              leftContent
            ) : (
              //TODO:temporarily hidden
              // <TextInput
              //   value={searchQuery}
              //   placeholder="Начните поиск"
              //   searchIcon
              //   isReset
              //   className="w-full bg-dark max-w-[540px] xl:max-w-[300px]"
              //   theme="dark"
              //   onChange={(value) =>
              //     dispatch(streamActions.setQuerySearch(value))
              //   }
              // />
              <div />
            )}
            {centralContent && centralContent}
            {breakPoint !== 'mobile' ? (
              <>
                {!rightButton && isStartStream ? (
                  <StartStreamButton onOpenModal={handleOpenModalAppStream} />
                ) : (
                  !isStartStream && rightButton
                )}
                {rightButton && isStartStream && (
                  <div className="flex flex-row gap-[16px]">
                    {rightButton}
                    <StartStreamButton onOpenModal={handleOpenModalAppStream} />
                  </div>
                )}
              </>
            ) : (
              <ControlPanelMenu
                content={
                  <>
                    {!rightButton && isStartStream ? (
                      <StartStreamButton
                        onOpenModal={handleOpenModalAppStream}
                        classNameButton="!text-tpg_base !justify-start"
                        classNameContainer="!top-[50px] !right-[30px]"
                        isShowIcon={false}
                      />
                    ) : (
                      !isStartStream && rightButton
                    )}
                    {rightButton && isStartStream && (
                      <div className="flex flex-col">
                        {rightButton}
                        <StartStreamButton
                          onOpenModal={handleOpenModalAppStream}
                          classNameButton="!text-tpg_base !justify-start"
                          classNameContainer="!top-[50px] !right-[40px]"
                          isShowIcon={false}
                        />
                      </div>
                    )}
                    <span
                      className={cn('cursor-pointer tpg-c2 text-tpg_base', {
                        '!text-bright_product': isOpenModalLogout,
                      })}
                      onClick={() => setOpenModalLogout(true)}
                    >
                      Выйти
                    </span>
                  </>
                }
              />
            )}
          </div>
        )}
      </div>
      {isOpenModalLogout && (
        <ConfirmModal
          title="Вы уверены, что хотите выйти?"
          description=""
          confirmText="Да, я хочу выйти"
          onConfirm={handleLogout}
          onClose={() => {
            setOpenModalLogout(false);
          }}
        />
      )}
      {isOpenModalAppStream && (
        <CopyModal
          onClick={handleStartStream}
          onClose={() => {
            setOpenModalAppStream(false);
          }}
        />
      )}
    </>
  );
};
